const { BACKEND_URL } = require('./constants');
const { getEntityURL, getEntityBreadcrumb } = require('../routing');
const htmlDecode = require('./htmlDecode');
const { getDefaultPictureBreakpoints } = require('./picture');

/**
 * Returns single object item.
 */
const getObjectValue = (entity, fieldName) => {
  if (!entity || typeof entity === 'undefined') {
    return null;
  }

  if (!entity[fieldName] || typeof entity[fieldName] !== 'object') {
    return null;
  }

  if (Array.isArray(entity[fieldName]) && typeof entity[fieldName][0] !== 'object') {
    return null;
  }

  return entity[fieldName][0] || entity[fieldName] || null;
};

/**
 * Returns array items.
 */
const getArrayValue = (entity, fieldName) => {
  if (!entity || typeof entity === 'undefined') {
    return [];
  }

  if (typeof entity[fieldName] !== 'object') {
    return [];
  }

  // Clean from NULL values.
  return entity[fieldName].filter((item) => item);
};

/**
 * Get filtered / plain text value from the field
 */
const getTextValue = (entity, fieldName) => {
  const field = getObjectValue(entity, fieldName);

  if (!field) {
    return '';
  }

  // If the field has "processed" property it means that it is
  // filtered text and the "processed" property must be used.
  if (field.hasOwnProperty('processed')) {
    return field.processed;
  }

  return field.hasOwnProperty('value') ? htmlDecode(String(field.value)) : '';
};

/**
 * Get text value from the field as usual and return it as number.
 */
const getNumberValue = (entity, fieldName) => {
  const field = getObjectValue(entity, fieldName);

  if (!field) {
    return 0;
  }

  return field.hasOwnProperty('value') ? Number.parseInt(field.value, 10) : 0;
};

/**
 * Get a bool value from the field as usual and return it.
 */
const getBooleanValue = (entity, fieldName) => {
  const field = getObjectValue(entity, fieldName);

  if (!field) {
    return false;
  }

  return field.hasOwnProperty('value') && field.value === true;
};

/**
 * Get value of link field. It is usually in the format of
 * { url: '', label: '' }
 */
const getLinkValue = (entity, fieldName) => {
  const link = getObjectValue(entity, fieldName);

  if (link) {
    const nextLink = getEntityURL(link);
    return {
      label: link.label,
      nextLink: nextLink.href ? nextLink : null,
    };
  }

  return {
    label: '',
    nextLink: null,
  };
};

/**
 * Get value of link field including the "external" flag.
 */
const getLinkExternal = (entity, fieldName) => {
  const link = getObjectValue(entity, fieldName);

  if (link) {
    const nextLink = getEntityURL(link);
    return {
      label: link.label,
      nextLink: nextLink.href ? nextLink : null,
      isExternal: link.url['is_external'] ?? false,
    };
  }

  return {
    label: '',
    nextLink: null,
    isExternal: false,
  };
};

/**
 * Returns image field URL to the given image style origin.
 */
const getImageURL = (entity, fieldName, imageStyle = 'original') => {
  const field = getObjectValue(entity, fieldName);

  if (!field) {
    return '';
  }

  if (!field.hasOwnProperty('image_styles')) {
    // Sometimes media image field has multiple nesting of field data. In
    // this case we need to make th
    return field.hasOwnProperty('field_media_image')
      ? getImageURL(field, 'field_media_image', imageStyle)
      : '';
  }

  // If for some reason image style is returned from the backend (like "original"),
  // then we can return the path to it straight away.
  if (field.image_styles.hasOwnProperty(imageStyle)) {
    return BACKEND_URL + field.image_styles[imageStyle];
  }

  // If image style does not contain a reference to the original image
  // then we don't have a basis to build a URL for the image style path.
  if (!('original' in field.image_styles)) {
    return '';
  }

  // Grab URL of the originally uploaded image.
  const originalURL = field.image_styles.original;

  // Define hardcoded value for Drupal file system.
  const filesPrefix = '/sites/default/files';

  // Get the second part of the original URL usually looking like
  // /media/folder/something.jpg.
  const publicFilePath = originalURL.split(filesPrefix)[1];

  // Build the full URL of the image based on Drupal's image styles pattern.
  const fullPath = `${filesPrefix}/styles/${imageStyle}/public${publicFilePath}`;

  if (entity.hasOwnProperty('changed')) {
    const changed = getTextValue(entity, 'changed');
    return `${BACKEND_URL}${fullPath}?changed=${changed}`;
  }
  return BACKEND_URL + fullPath;
};

/**
 * Return image field's alt.
 */
const getImageAlt = (entity, fieldName) => {
  const field = getObjectValue(entity, fieldName);

  if (!field) {
    return '';
  }

  if (!field.hasOwnProperty('alt')) {
    // Sometimes media image field has multiple nesting of field data. In
    // this case we need to make th
    if (field.hasOwnProperty(fieldName)) {
      return getImageAlt(field, fieldName);
    }

    if (field.hasOwnProperty('field_media_image')) {
      return getImageAlt(field, 'field_media_image');
    }

    return '';
  }

  return field.alt;
};

/**
 * Return image caption text.
 */
const getImageCaption = (entity, fieldName) => {
  const field = getObjectValue(entity, fieldName);

  if (!field) {
    return '';
  }

  return getTextValue(field, 'field_caption');
};

/**
 * Return image mime type.
 */
const getImageMime = (entity, fieldName) => {
  const field = getObjectValue(entity, fieldName);

  if (!field) {
    return '';
  }

  if (!field.hasOwnProperty('filemime')) {
    // Sometimes media image field has multiple nesting of field data. In
    // this case we need to make the same with field_media_image.
    return field.hasOwnProperty('field_media_image')
      ? getImageMime(field, 'field_media_image')
      : '';
  }

  return getTextValue(field, 'filemime');
};

/**
 * Returns image object with both url and alt fields in place.
 */
const getImage = (entity, fieldName, imageStyle = 'original') => ({
  url: getImageURL(entity, fieldName, imageStyle),
  alt: getImageAlt(entity, fieldName),
});

/**
 * Returns image object in small, medium and large variants.
 */
const getResponsiveImage = (
  entity,
  defaultFieldName,
  imageStylePrefix,
  fieldNameSmall,
  fieldNameMedium,
  fieldNameLarge,
) => {
  let small = {};
  let medium = {};
  let large = {};

  if (fieldNameSmall) {
    small = {
      url: getImageURL(entity, fieldNameSmall, `${imageStylePrefix}_mobile`),
      alt: getImageAlt(entity, fieldNameSmall),
    };
  }

  if (fieldNameMedium) {
    medium = {
      url: getImageURL(entity, fieldNameMedium, `${imageStylePrefix}_tablet`),
      alt: getImageAlt(entity, fieldNameMedium),
    };
  }

  if (fieldNameLarge) {
    large = {
      url: getImageURL(entity, fieldNameLarge, `${imageStylePrefix}_desktop`),
      alt: getImageAlt(entity, fieldNameLarge),
    };
  }

  return {
    small: small.url
      ? small
      : {
          url: getImageURL(entity, defaultFieldName, `${imageStylePrefix}_mobile`),
          alt: getImageAlt(entity, defaultFieldName),
        },
    medium: medium.url
      ? medium
      : {
          url: getImageURL(entity, defaultFieldName, `${imageStylePrefix}_tablet`),
          alt: getImageAlt(entity, defaultFieldName),
        },
    large: large.url
      ? large
      : {
          url: getImageURL(entity, defaultFieldName, `${imageStylePrefix}_desktop`),
          alt: getImageAlt(entity, defaultFieldName),
        },
  };
};

/**
 * Return properly file URL from the file field.
 */
const getFileURL = (entity, fieldName) => {
  const file = getObjectValue(entity, fieldName);

  if (!file) {
    return '';
  }

  const uri = getObjectValue(file, 'uri');
  return uri && uri.hasOwnProperty('url') ? BACKEND_URL + uri.url : '';
};

/**
 * Return file mime name of the file.
 * Usually it's something like "application/pdf".
 */
const getFileMime = (entity, fieldName) => {
  const file = getObjectValue(entity, fieldName);

  if (!file) {
    return '';
  }

  return getTextValue(file, 'filemime');
};

/**
 * Return file size value.
 * The value is in bytes.
 */
const getFileSize = (entity, fieldName) => {
  const file = getObjectValue(entity, fieldName);

  if (!file) {
    return 0;
  }

  return getNumberValue(file, 'filesize');
};

/**
 * Return data for picture component.
 *
 * Example of breakpointsSettings:
 * {
 *   'max-sm': {
 *     '1x': image_style_for_1x_name,
 *     '2x': image_style_for_2x_name,
 *   },
 *   sm: {
 *     '1x': image_style_for_1x_name,
 *   },
 * }
 */
const getPicture = (entity, fieldName, breakpointsSettings, fallbackImageStyle) => {
  const availableBreakpoints = getDefaultPictureBreakpoints();

  if (!entity || !fieldName || !breakpointsSettings) {
    return null;
  }

  // Filter available breakpoints and sort them.
  const breakpoints = Object.keys(availableBreakpoints)
    .filter((breakpoint) => breakpointsSettings[breakpoint])
    .reverse();

  const sources = [];
  breakpoints.forEach((breakpoint) => {
    let srcSet = '';
    let srcSetWebP = '';
    const multipliers = Object.keys(breakpointsSettings[breakpoint]);
    multipliers.forEach((multiplier, index) => {
      const imageUrl = getImageURL(entity, fieldName, breakpointsSettings[breakpoint][multiplier]);

      if (!imageUrl) {
        return;
      }

      const imageUrlObject = new URL(imageUrl);
      const pathname = imageUrlObject.pathname;
      imageUrlObject.pathname = `${pathname.substring(0, pathname.lastIndexOf('.'))}.webp`;
      const imageUrlWebP = imageUrlObject.toString();

      if (multipliers.length === 1) {
        srcSet = imageUrl;
        srcSetWebP = imageUrlWebP;
      } else {
        srcSet +=
          index === multipliers.length - 1
            ? `${imageUrl} ${multiplier}`
            : `${imageUrl} ${multiplier}, `;
        srcSetWebP +=
          index === multipliers.length - 1
            ? `${imageUrlWebP} ${multiplier}`
            : `${imageUrlWebP} ${multiplier}, `;
      }
    });

    if (!srcSet) {
      return;
    }

    // Add webP images to source.
    sources.push({
      media: availableBreakpoints[breakpoint],
      type: 'image/webp',
      // We need to place 'media' before 'srcSet' when listing the attributes.
      // Adding 'srcSet' before 'media' can cause certain browsers to download two images.
      srcSet: srcSetWebP,
    });

    // Add default images to source.
    sources.push({
      srcSet,
      media: availableBreakpoints[breakpoint],
      type: getImageMime(entity, fieldName),
    });
  });

  if (sources.length === 0) {
    return null;
  }

  return {
    sources,
    fallbackSrc: getImageURL(entity, fieldName, fallbackImageStyle),
    alt: getImageAlt(entity, fieldName),
  };
};

/**
 * Returns human readable label of the entity.
 * Usually it's used for node types.
 */
const getEntityBundleLabel = (entity) => {
  const entityBundleLabels = {
    appeal: 'Appeal',
    country: 'Country',
    news: 'News',
    organisation: 'Organisation',
    page: 'Page',
    press_release: 'Press release',
    person: 'Person',
    publication: 'Publication',
    standard_info_page: 'Info',
    section_index_page: 'Section',
    story: 'Story',
  };

  const type = getTextValue(entity, 'entity_bundle');
  if (!type) {
    return '';
  }

  return entityBundleLabels.hasOwnProperty(type) ? entityBundleLabels[type] : '';
};

const getPriceValue = (entity, fieldName) => {
  if (!entity[fieldName] || !Array.isArray(entity[fieldName])) {
    return null;
  }
  const item = entity[fieldName][0];
  if (!item) {
    return null;
  }
  return {
    amount: item.number,
    currency: item.currency_code,
  };
};

/**
 * Returns amount impacts.
 */
const getImpacts = (entity, fieldName) =>
  getArrayValue(entity, fieldName).map((impact) => ({
    ...impact,
    image: getObjectValue(impact, 'image') ? getImage(impact, 'image', 'impact_thumbnail') : null,
  }));

const getFundraiseUpParams = (tile, fieldName) => {
  const button = getLinkValue(tile, fieldName);

  const parts = button.nextLink?.url?.split('?');
  if (parts?.length < 2) {
    return null;
  }

  const query = new URLSearchParams(parts[1]);
  if (!query.has('cw_fru', 1) || !query.has('form')) {
    return null;
  }

  return query.toString();
};

const getLinkFundraiseUp = (tile, fieldName) => {
  const button = getLinkValue(tile, fieldName);
  // Override Donate Now CTA with FRU parameters only in case FRU is enabled
  // and link contains required query parameters.
  const query_string = getFundraiseUpParams(tile, fieldName);
  if (query_string) {
    const path = `?${query_string}`;
    button.nextLink = {
      url: path,
      route: null,
      isExternal: false,
      href: path,
      as: path,
      title: '',
    };
  }
  return button;
};

// The export is done this way so that node.js can also
// require these functions.
module.exports = {
  // Export getEntityURL and getEntityBreadcrumb functions to keep all
  // field-level transforms in the same place eventually.
  getEntityURL,
  getEntityBreadcrumb,
  // Other native exports.
  getObjectValue,
  getArrayValue,
  getTextValue,
  getNumberValue,
  getBooleanValue,
  getLinkValue,
  getLinkExternal,
  getImageURL,
  getImageAlt,
  getImageCaption,
  getImage,
  getPicture,
  getResponsiveImage,
  getFileURL,
  getFileMime,
  getFileSize,
  getEntityBundleLabel,
  getImpacts,
  getPriceValue,
  getFundraiseUpParams,
  getLinkFundraiseUp,
};
