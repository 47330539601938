// TODO: Write proper definitions for all prop types.
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import config from 'next/config';
import { Provider } from 'react-redux';
import { wrapper } from '../store/store';
import CNETApplication from '../apps/cnet_app';
import GiftsApplication from '../apps/gifts_app';
import { sessionStorageGetItem, sessionStorageRemoveItem } from '../utils/web-storage';
import { webVitalsHandler } from '../utils/web-vitals';
import { cookieBotConsentExpirationCheck } from '../utils/cookiebot';
import { pushPageview as gtmPushPageview } from '../utils/gtm';
import { clonePrevention } from '../utils/clonePrevention';
import { FRONTEND_CNET_URL } from '../utils/constants';
import routerEvents from 'next-router-events';

import '../components/01_atoms/PageTransitionBar';
import '../components/01_atoms/PageTransitionBar/_style.scss';
import '../components/01_atoms/CookieBot/style.scss';

// Internal debugging.
const debug = require('debug')('cw:_app.js');

const {
  publicRuntimeConfig: { PAYMENT_SECRET_HEADER_NAME, ENVIRONMENT, ROLLBAR_CLIENT_ACCESS_TOKEN },
  serverRuntimeConfig: { PAYMENT_SECRET_HEADER_VALUE },
} = config();

// Report web vitals.
// See https://nextjs.org/docs/pages/building-your-application/optimizing/analytics#web-vitals
export function reportWebVitals(metric) {
  // Only report web-vital events.
  if (metric.label !== 'web-vital') {
    return;
  }

  // Currently Web Vitals library (https://github.com/GoogleChrome/web-vitals)
  // which is used under the hood in next.js reportWebVitals method,
  // can't really count LCP for video. Most likely it is so because it is not
  // supported by browsers Performance API, see
  // https://developer.mozilla.org/en-US/docs/Web/API/LargestContentfulPaint,
  // so we do not sending such values at this moment.
  if (
    metric.name === 'LCP' &&
    metric.value === 0 &&
    metric.attribution &&
    metric.attribution.lcpEntry &&
    metric.attribution.lcpEntry.element &&
    metric.attribution.lcpEntry.element.tagName === 'VIDEO'
  ) {
    return;
  }

  webVitalsHandler(metric);
}

const Application = (rest) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  let App;

  if (props.app === 'gifts') {
    App = GiftsApplication;
  } else {
    App = CNETApplication;
  }

  useEffect(() => {
    // Clean up locally stored web vitals on each page load.
    // Basically it covers the case when user reloads the page before
    // accepting cookies, or visits policies pages by links in cookie popup.
    const localEvents = sessionStorageGetItem('cw_web_vitals');
    if (localEvents) {
      sessionStorageRemoveItem('cw_web_vitals');
    }
    // Check this is not a cloned site
    clonePrevention(ROLLBAR_CLIENT_ACCESS_TOKEN, ENVIRONMENT);

    // Add a page listener to automatically delete Cookiebot consent
    // older than 6 months.
    cookieBotConsentExpirationCheck();
    // Add a Cookiebot Consent event listener to report locally stored metrics.
    window.addEventListener('CookiebotOnAccept', webVitalsHandler);
    // Listening "on decline" event as well since it is fired when user accepts
    // only necessary cookies. It needs to remove locally stored data
    // immediately.
    window.addEventListener('CookiebotOnDecline', webVitalsHandler);

    // We can't track page views very well by GTM in SPA, so
    // we have to send custom pageview event on every routeChangeComplete.
    gtmPushPageview();
    routerEvents.on('routeChangeComplete', gtmPushPageview);

    return () => {
      // Add a Cookiebot Consent event listener for Cookiebot consent.
      window.removeEventListener('CookiebotOnAccept', webVitalsHandler);
      window.removeEventListener('CookiebotOnDecline', webVitalsHandler);

      routerEvents.off('routeChangeComplete', gtmPushPageview);
    };
  }, []);

  return (
    <Provider store={store}>
      <App {...props} />
    </Provider>
  );
};

Application.getInitialProps = wrapper.getInitialPageProps((store) => async (props) => {
  const {
    ctx: { res, req, query },
  } = props;

  let initialProps = {};
  let App;

  if (
    (typeof window !== 'undefined' && window.location.host.startsWith('gifts')) ||
    (props.ctx?.req?.headers.host && props.ctx.req.headers.host.startsWith('gifts'))
  ) {
    App = GiftsApplication;
    initialProps.app = 'gifts';
  } else {
    App = CNETApplication;
    initialProps.app = 'cnet';
  }

  initialProps.testSuite = false;
  let cookie;
  if (req) {
    cookie = new Cookies(req.cookies);
  } else {
    cookie = new Cookies(document.cookies);
  }

  // Determine environment for GTM.
  if (ENVIRONMENT === 'production') {
    // On live site we don't want to collect statistics in live GA
    // from users who are manage the site. In this case we use
    // development GTM environment if user has 'isAdmin' cookie.
    // Also we set `isAdmin` cookie when user use test payment methods
    // on the live site, so GTM will be automatically switched to dev mode.
    /** @link https://www.pivotaltracker.com/story/show/170827963 */
    if (
      PAYMENT_SECRET_HEADER_NAME &&
      PAYMENT_SECRET_HEADER_VALUE &&
      query[PAYMENT_SECRET_HEADER_NAME] &&
      query[PAYMENT_SECRET_HEADER_NAME] === PAYMENT_SECRET_HEADER_VALUE
    ) {
      // Enable development GTM for test payments.
      initialProps.GTMDevMode = true;
      res.cookie('isAdmin', true, {
        expires: new Date(Date.now() + 1000 * 24 * 60 * 60 * 1000),
      });
    } else {
      // Enable development GTM for users who has 'isAdmin' cookie.
      // Enable production GTM for other users.
      initialProps.GTMDevMode = cookie.get('isAdmin') === 'true';
    }
  } else {
    // Enable development GTM for non-production environments.
    initialProps.GTMDevMode = true;
  }

  initialProps.isPreviewMode = !!query.previewMode;

  try {
    // Merge original props with props returned from page component.
    const childInitialProps = await App.getInitialProps({
      store,
      ...props,
    });
    initialProps = { ...initialProps, ...childInitialProps };
  } catch (e) {
    initialProps.statusCode = 502;
    debug(`Error during getting initial props from ${initialProps.app} application. Error: %O`, e);
  }

  // Setting test suite mode by cookie or URL parameter.
  // It allows disabling 3rd-party scripts loading during tests.
  const testSuiteCookie = cookie.get('cw_test_suite');
  if (query['test-suite'] === 'true' || testSuiteCookie) {
    initialProps.testSuite = true;
    if (!testSuiteCookie && res) {
      res.cookie('cw_test_suite', true);
    }
  }

  if (res) {
    // Avoid "Clickjacking" attack on cnet and gifts websites.
    res.setHeader('X-Frame-Options', 'SAMEORIGIN');
    res.setHeader('Content-Security-Policy', "frame-ancestors 'self';");

    // Enforce "Strict transport security".
    let strictTransportSecurity = 'max-age=31536000; includeSubDomains';

    // Add "preload" option only on Live domains.
    if (['concern.net', 'concern.org.uk'].some((domain) => FRONTEND_CNET_URL.endsWith(domain))) {
      strictTransportSecurity += '; preload';
    }

    res.setHeader('Strict-Transport-Security', strictTransportSecurity);
  }

  return initialProps;
});

export default Application;
