import React, { useEffect } from 'react';
import { BehaviorSettings } from '../../../../types';
import { FieldInputProps } from 'react-final-form';
import { useRouter } from 'next/router';
import { Field, Form, RadiosButton, TextField } from '../../../01_atoms/FormElements';
import { currencySymbolPath, formatAmount, getSymbol } from '../../../../utils/currency';
import Button from '../../../01_atoms/Button';
import HeroImageWithShapedBackground from './HeroImageWithShapedBackground';
import HeroMoneyHandles, { MoneyHandle } from './HeroMoneyHandles';
import { getImpact } from '../../../../utils/donationForm';
import { parseOnlyDigits } from '../../../../utils/formFieldParsers';
import { minValue } from '../../../../utils/formFieldValidators';
import { pushViewItem as gtmPushViewItem } from '../../../../utils/gtm';
import { generateClassNameByBehaviorSettings } from '../../../../utils/behaviorSettings';
import FormattedText from '../../../01_atoms/FormattedText';

import styles from './index.module.scss';

// TODO: Import the type from ResponsiveImage when component is migrated to
//  TypeScript or ideally replace by DefaultPicture.
type Image = {
  small: {
    url?: string;
    alt?: string;
  };
  medium: {
    url?: string;
    alt?: string;
  };
  large: {
    url?: string;
    alt?: string;
  };
};

type Impact = {
  amount: number;
  text?: string;
  image?: {
    url: string;
    alt: string;
  };
};

type FormValues = {
  donation_type: 'single_donation' | 'recurring_donation';
  money_handle: {
    single_donation: number;
    recurring_donation: number;
    last_valid?: {
      single_donation?: number;
      recurring_donation?: number;
    };
  };
  amount: {
    single_donation: string;
    recurring_donation: string;
  };
};

type MoneyHandleSet = {
  money_handles: {
    single_donation?: MoneyHandle[];
    recurring_donation?: MoneyHandle[];
  };
  preselectedSingle?: number;
  preselectedMonthly?: number;
};

type Props = {
  title: string;
  image?: Image;
  currency: 'GBP' | 'EUR';
  defaultRecurrence?: 'single_donation' | 'recurring_donation';
  optionsSingle?: MoneyHandle[];
  optionsMonthly?: MoneyHandle[];
  preselectedSingle?: number;
  preselectedMonthly?: number;
  bottomDescription?: string;
  monthlyInfoText?: string;
  singleDonationLabel?: string;
  recurringDonationLabel?: string;
  styling?: 'normal' | 'emergency' | 'dec';
  onSubmit: (values: FormValues) => void;
  behaviorSettings?: BehaviorSettings;
  uuid: string;
  defaultAppealId: string;
  defaultAppealTitle: string;
  impactsSingleDonation?: Impact[];
  impactsMonthlyDonation?: Impact[];
  additionalMoneyHandles?: {
    [key: string]: MoneyHandleSet;
  };
};

const BBHeroWithMoneyHandles = ({
  uuid,
  title,
  image,
  currency,
  defaultRecurrence = 'single_donation',
  optionsSingle = [],
  optionsMonthly = [],
  impactsSingleDonation = [],
  impactsMonthlyDonation = [],
  preselectedSingle = 1,
  preselectedMonthly = 1,
  bottomDescription = '',
  monthlyInfoText = '',
  singleDonationLabel = 'One-off',
  recurringDonationLabel = 'Give monthly',
  styling = 'normal',
  additionalMoneyHandles,
  onSubmit,
  behaviorSettings,
  // Data for GA.
  defaultAppealId,
  defaultAppealTitle,
}: Props) => {
  // The block supports multiple sets of money handles, the default ones
  // and additional ones, which are displayed if URL contains appropriate
  // source code value.
  const router = useRouter();
  const sourceCode = router.query.source;
  const moneyHandleSet: MoneyHandleSet | undefined =
    sourceCode && typeof sourceCode === 'string' && additionalMoneyHandles?.[sourceCode]
      ? additionalMoneyHandles?.[sourceCode]
      : undefined;

  const moneyHandlesSingle = moneyHandleSet?.money_handles.single_donation?.length
    ? moneyHandleSet?.money_handles.single_donation
    : optionsSingle;
  const moneyHandlesMonthly = moneyHandleSet?.money_handles.recurring_donation?.length
    ? moneyHandleSet?.money_handles.recurring_donation
    : optionsMonthly;

  const moneyHandles = {
    single_donation: moneyHandlesSingle.map((item, index) => ({
      value: `${index}`,
      label: formatAmount(currency, item.amount),
      ...moneyHandlesSingle[index],
    })),
    recurring_donation: moneyHandlesMonthly.map((item, index) => ({
      value: `${index}`,
      label: formatAmount(currency, item.amount),
      ...moneyHandlesMonthly[index],
    })),
  };

  // Avoid inheriting default set's preselected values for additional sets.
  const preselectedHandleSingle = moneyHandleSet?.money_handles.single_donation?.length
    ? (moneyHandleSet?.preselectedSingle ?? 1)
    : preselectedSingle;
  const preselectedHandleMonthly = moneyHandleSet?.money_handles.recurring_donation?.length
    ? (moneyHandleSet?.preselectedMonthly ?? 1)
    : preselectedMonthly;

  const initialValues = {
    donation_type: defaultRecurrence,
    // Form values structure always supports both single & monthly donations
    // so it's easier to switch between then and keep the correct state.
    money_handle: {
      single_donation: `${preselectedHandleSingle}`,
      recurring_donation: `${preselectedHandleMonthly}`,
    },
    amount: {
      single_donation: moneyHandles.single_donation[preselectedHandleSingle]
        ? `${moneyHandles.single_donation[preselectedHandleSingle].amount}`
        : '',
      recurring_donation: moneyHandles.recurring_donation[preselectedHandleMonthly]
        ? `${moneyHandles.recurring_donation[preselectedHandleMonthly].amount}`
        : '',
    },
  };

  useEffect(() => {
    if (defaultAppealId) {
      gtmPushViewItem([
        {
          item_id: defaultAppealId,
          item_name: defaultAppealTitle,
          item_category: 'Appeal',
          item_variant: defaultRecurrence,
        },
      ]);
    }
  }, [uuid, defaultAppealId, defaultAppealTitle, defaultRecurrence]);

  // Update selected money handle on custom amount change.
  // The types for "input" and "mutators" are taken from React final form,
  // and yes, they contain explicit "any", unfortunately.
  function onAmountChange(
    event: React.ChangeEvent<HTMLInputElement>,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    input: FieldInputProps<any, HTMLElement>,
    values: FormValues,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    mutators: Record<string, (...args: any[]) => any>,
  ) {
    // Call default onChange handler.
    input.onChange(event);

    const index = moneyHandles[values.donation_type].findIndex(
      (i) => i.amount === parseInt(event.target.value, 10),
    );

    // Store the last valid amount from the money handles to keep impact message for it.
    const currentAmountIndex = moneyHandles[values.donation_type].findIndex(
      (i) => i.amount === parseInt(values.amount[values.donation_type], 10),
    );
    if (currentAmountIndex >= 0) {
      mutators.setValue(
        `money_handle.last_valid.${values.donation_type}`,
        values.amount[values.donation_type],
      );
    }
    if (index === -1) {
      // Reset radio button.
      mutators.setValue(`money_handle.${values.donation_type}`, undefined);
    } else {
      mutators.setValue(`money_handle.${values.donation_type}`, `${index}`);
    }
  }

  async function onFormSubmit(values: FormValues) {
    await onSubmit(values);
  }

  const customAmountLabel = 'Or enter your own amount';

  const getCurrentImpact = (values: FormValues) => {
    if (!values) {
      return null;
    }

    let currentImpact;
    if (values.donation_type === 'single_donation' && values.amount.single_donation) {
      currentImpact = getImpact(+values.amount.single_donation, impactsSingleDonation);
    }

    if (values.donation_type === 'recurring_donation' && values.amount.recurring_donation) {
      currentImpact = getImpact(+values.amount.recurring_donation, impactsMonthlyDonation);
    }

    if (currentImpact) {
      return currentImpact;
    }

    // Keep the impact with the last know valid amount.
    if (
      values.donation_type === 'single_donation' &&
      values.money_handle.last_valid &&
      values.money_handle.last_valid.single_donation
    ) {
      currentImpact = getImpact(
        +values.money_handle.last_valid.single_donation,
        impactsSingleDonation,
      );
    }

    if (
      values.donation_type === 'recurring_donation' &&
      values.money_handle.last_valid &&
      values.money_handle.last_valid.recurring_donation
    ) {
      currentImpact = getImpact(
        +values.money_handle.last_valid.recurring_donation,
        impactsMonthlyDonation,
      );
    }
    return currentImpact || null;
  };

  const classes = [
    'bb',
    'bb-appeal-hero-with-money-handles',
    styles['appeal-hero-with-money-handles'],
    styles['bb-appeal-hero-with-money-handles'],
    styles[`appeal-hero-with-money-handles--${styling}`],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div id={uuid} className={classes.join(' ')}>
      <HeroImageWithShapedBackground image={image} showDec={styling === 'dec'} />
      <div className="container">
        <div className="form-card">
          <h1 className="header-two large">{title}</h1>

          <Form
            onSubmit={onFormSubmit}
            initialValues={initialValues}
            mutators={{
              // Create a mutator which allows to modify field's value by external callbacks.
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            subscription={{ submitting: true, values: true }}
            render={({ handleSubmit, submitting, values, form: { mutators } }) => (
              <form onSubmit={handleSubmit}>
                {moneyHandlesSingle.length && moneyHandlesMonthly.length ? (
                  <RadiosButton
                    name="donation_type"
                    mutateFormValue={mutators.setValue}
                    options={[
                      { value: 'single_donation', label: singleDonationLabel },
                      { value: 'recurring_donation', label: recurringDonationLabel },
                    ]}
                    styling={styling === 'normal' ? 'default' : 'emergency'}
                  />
                ) : (
                  <div className="donation-type">
                    <span>
                      {moneyHandlesMonthly.length ? 'Monthly donation' : 'Single donation'}
                    </span>
                  </div>
                )}

                <HeroMoneyHandles
                  moneyHandles={moneyHandles}
                  donationType={values.donation_type}
                  currentImpact={getCurrentImpact(values)}
                  mutateFormValue={mutators.setValue}
                  styling={styling === 'normal' ? 'default' : 'emergency-black'}
                />

                <label className="field-amount-label" htmlFor={`amount.${values.donation_type}`}>
                  {customAmountLabel}
                </label>
                <Field
                  name={`amount.${values.donation_type}`}
                  type="text"
                  parse={parseOnlyDigits}
                  validate={minValue(1, null)}
                >
                  {({ input }) => (
                    <TextField
                      name={`amount.${values.donation_type}`}
                      // id and onChange props are not defined in react-final-form type.
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      id={`amount.${values.donation_type}`}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                        onAmountChange(ev, input, values, mutators)
                      }
                      icon={currencySymbolPath[currency]}
                      label=""
                      placeholder=""
                      // Enable numeric keyboard for mobile devices.
                      inputMode="numeric"
                      pattern="[0-9]+"
                      infoOnPatternNotMatched="Please use numbers only"
                      aria-label={getSymbol(currency)}
                    />
                  )}
                </Field>

                <Button
                  type={styling === 'normal' ? 'primary' : 'emergency'}
                  size="extra-large"
                  isLoading={submitting}
                  tabIndex={0}
                  withArrow
                >
                  DONATE NOW
                </Button>
                {bottomDescription && (
                  <p className={styles['bb-appeal-hero-with-money-handles__bottom-description']}>
                    {bottomDescription}
                  </p>
                )}
                {!!monthlyInfoText &&
                  values.donation_type === 'recurring_donation' &&
                  styling !== 'normal' && (
                    <div className={styles['bb-appeal-hero-with-money-handles__monthly-info']}>
                      <FormattedText
                        className={styles['bb-appeal-hero-with-money-handles__monthly-info-text']}
                        text={monthlyInfoText}
                      />
                    </div>
                  )}
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default BBHeroWithMoneyHandles;
