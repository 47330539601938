import TruckThinIcon from '../../public/static/icons/truck-thin.svg';
import { DEFAULT_COUNTRY_CODE } from '../../utils/constants';

import s from './index.module.scss';

export type Props = {
  title: string;
  description: string;
  additionalDescription?: string;
};

const ShippingInfoMessage = ({
  title = 'Shipping to Ireland and UK only',
  description = 'Order by 17th December to ensure pre-Christmas delivery of physical gift cards.',
  additionalDescription = "If you need a card in time for Christmas day - don't worry! We have e-card or print-at-home options that can be emailed to you immediately upon purchase.",
}: Props) => {
  if (DEFAULT_COUNTRY_CODE !== 'IE') {
    return null;
  }
  return (
    <div className={s['shipping-info-message']}>
      <TruckThinIcon className={s['shipping-info-message__icon']} />
      <div className={s['shipping-info-message__message']}>
        <div className={s['shipping-info-message__title']}>{title}</div>
        <div className={s['shipping-info-message__description']}>
          <strong>{description}</strong>
          <div>{additionalDescription}</div>
        </div>
      </div>
    </div>
  );
};

export default ShippingInfoMessage;
